var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.images.length > 0)?_c('v-card',{class:{'px-3 px-sm-6 pb-3 pb-sm-6': _vm.isProfileGroups, 'mt-4': !_vm.isProfileGroups},staticStyle:{"width":"100%","border":"1px solid"},style:({
    borderColor: _vm.cardBorderColor ?
      _vm.$vuetify.theme.themes.light.boxBackground :
      _vm.$vuetify.theme.themes.light.box,
    backgroundColor: _vm.$vuetify.theme.themes.light.boxBackground
  }),attrs:{"elevation":"0","loading":_vm.isLoading}},[(_vm.showCardTitle)?_c('v-card-title',{staticClass:"d-flex pa-0 mt-3 mt-sm-6 align-center justify-space-between"},[_c('button',{staticClass:"d-flex align-center",on:{"click":function($event){$event.preventDefault();return _vm.seeProfile($event)}}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"36","color":"transparentBackground"}},[(_vm.profileImage)?_c('img',{attrs:{"src":_vm.profileImage}}):_vm._e()]),_c('span',{staticClass:"text-subtitle-2 text-sm-subtitle-1 font-weight-medium"},[_vm._v(_vm._s(_vm.profileName))])],1),_c('square-button',{attrs:{"color":"#0000004d","icon-color":"white","icon-name":"mdi-close","fab":"","small":_vm.isMobileVersion,"menu-disabled":""},on:{"clicked":function($event){return _vm.$emit('close')}}})],1):_vm._e(),_c('v-carousel',{class:{'mt-3 mt-sm-6': _vm.isProfileGroups},attrs:{"height":_vm.carouselHeight && !_vm.isMobileVersion ? _vm.carouselHeight : _vm.imageSize,"hide-delimiters":"","hide-delimiter-background":"","show-arrows-on-hover":"","next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.images),function(img,i){return _c('v-carousel-item',{key:i,staticStyle:{"position":"relative"},style:({ height: _vm.imageSize }),attrs:{"eager":""}},[_c('image-container',{staticClass:"transparentBackground carouselImage",class:{'carouselProfileImages': _vm.isProfileCarousel,
                 'carouselRelatedProfiles': _vm.isRelatedProfiles},attrs:{"height":"100%","width":"100%","contain":"","rounded":"","loading":_vm.loading,"src":_vm.loading ? '' : img.mediumImage || img.originalFrame || img.frame,"lazy-src":_vm.loading ? '' : img.frameThumbnail,"has-zoom":true},on:{"isZoomDestroyed":_vm.removeZoomedImages,"isZoomEnabled":function($event){return _vm.saveZoomedImages(i)},"reload":_vm.onReloadImageButtonClicked}}),(_vm.canShowBoundingBox && _vm.isBoundingBoxValid && !_vm.isZoomEnabled)?_c('div',{staticStyle:{"position":"absolute","border":"1px solid #8F93FF"},style:({
          height: ((_vm.boundingBoxStyle.height) + "px"),
          width: ((_vm.boundingBoxStyle.width) + "px"),
          top: _vm.boundingBoxStyle.top,
          left: _vm.boundingBoxStyle.left
        })},[_c('span',{staticClass:"d-flex justify-center align-center",style:({
            position: 'absolute',
            background: '#8F93FF',
            bottom: '-22px',
            width: 'max-content',
            'min-width': '100%',
            height: '22px',
            'font-size': ("" + (_vm.isMobileVersion ? '12px' : '18px')),
          })},[_vm._v(" "+_vm._s(_vm.boundingBoxSize(img))+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex",staticStyle:{"position":"absolute","top":"8px","left":"8px"}},[(_vm.showFacePreview)?_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative","min-height":"35px","min-width":"35px","transition":"width 5s, height 5s"}},[(_vm.showPreview)?[_c('image-container',{staticClass:"mr-1 transparentBackground elevation-2",attrs:{"rounded":"","loading":_vm.loading,"height":_vm.avatarSize,"width":_vm.avatarSize,"src":_vm.loading ? undefined : img.face,"lazy-src":_vm.loading ? undefined : img.faceThumbnail},on:{"reload":_vm.onReloadImageButtonClicked}}),_c('image-container',{staticClass:"mr-1 transparentBackground elevation-2",attrs:{"rounded":"","loading":_vm.loading,"height":_vm.avatarSize,"width":_vm.avatarSize,"src":_vm.profileImage},on:{"reload":_vm.onReloadImageButtonClicked}})]:_vm._e()],2):_vm._e()])],1)}),1),(_vm.isMobileVersion)?_c('div',{staticClass:"d-flex mt-2 "},_vm._l((_vm.images),function(img,i){return _c('div',{key:i,staticClass:"circleDelimiterMobile",style:({
        borderColor: _vm.model == i ? _vm.$vuetify.theme.currentTheme.primary : '',
        backgroundColor: _vm.model == i ? _vm.$vuetify.theme.currentTheme.primary : ''
      }),on:{"click":function($event){_vm.model = i}}})}),0):_vm._e(),_c('v-card-actions',{staticClass:"d-flex pa-0 mt-3 mt-sm-6 align-center flex-wrap",class:{'justify-start': _vm.isMobileVersion, 'justify-space-between': !_vm.isMobileVersion}},[(!_vm.isMobileVersion)?_c('div',{staticClass:"d-flex align-center",class:{'mb-4': _vm.imageSize < 400 &&
        _vm.images.length > 2}},[_vm._l((_vm.images),function(img,i){return _c('div',{key:i,staticClass:"circleDelimiter",style:({
          borderColor: _vm.model == i ? _vm.$vuetify.theme.currentTheme.primary : '',
          backgroundColor: _vm.model == i ? _vm.$vuetify.theme.currentTheme.primary : ''
        }),on:{"click":function($event){_vm.model = i}}})}),_c('span',{staticClass:"d-none d-md-flex ml-2 neutral--text subtitle-2 text-sm-subtitle-1"},[_vm._v(" "+_vm._s(_vm.model + 1)+"/"+_vm._s(_vm.images.length)+" ")])],2):_vm._e(),(!_vm.isMobileVersion)?_c('v-spacer'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.showFacePreview)?_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"neutral","small":_vm.isMobileVersion},on:{"click":_vm.setShowPreview}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.isMobileVersion}},[_vm._v(" "+_vm._s(_vm.showPreview ? 'mdi-eye-off-outline' : 'mdi-eye-outline')+" ")])],1):_vm._e()]}}],null,false,3678814393)},[_c('span',[_vm._v(" "+_vm._s(_vm.showPreview ? _vm.$t('deconve.hideFacePreview') : _vm.$t('deconve.showFacePreview'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{'ml-2': _vm.showFacePreview},attrs:{"outlined":"","disabled":_vm.isZoomEnabled,"color":"neutral","small":_vm.isMobileVersion},on:{"click":_vm.setBoundingBoxStatus}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.isMobileVersion}},[_vm._v(" "+_vm._s(_vm.showBoundingBox ? 'mdi-square-off-outline' : 'mdi-square-outline')+" ")])],1)]}}],null,false,2292264837)},[_c('span',[_vm._v(" "+_vm._s(_vm.showBoundingBox ? _vm.$t('deconve.notification.unmarkFace') : _vm.$t('deconve.notification.markFace'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":_vm.hasOriginalImage ? 'info' : 'neutral',"outlined":!_vm.hasOriginalImage,"elevation":"0","small":_vm.isMobileVersion},on:{"click":function($event){return _vm.$emit('downloadOriginalImage', _vm.model)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.isMobileVersion}},[_vm._v(" mdi-quality-high ")])],1)]}}],null,false,3274511150)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('deconve.imageHighQuality'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(_vm.showAddToProfile && _vm.$can('update', 'com.deconve.faceid.person'))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 actionsButton",attrs:{"color":"neutral","outlined":"","small":_vm.isMobileVersion},on:{"click":_vm.addImageToProfile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.isMobileVersion}},[_vm._v(" mdi-plus-box-multiple ")])],1):_vm._e()]}}],null,false,591949784)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('deconve.notification.addImageToProfile'))+" ")])])],1)],1):_c('div',{staticClass:"flex-column align-end justify-end",staticStyle:{"flex":"1"}},[_c('v-skeleton-loader',{staticClass:"mb-2",style:({ height: (_vm.imageSize + "px"), width: '100%' }),attrs:{"type":"image@3"}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-skeleton-loader',{staticStyle:{"flex":"1"},attrs:{"type":"heading"}}),_c('v-skeleton-loader',{attrs:{"type":"chip","tile":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }