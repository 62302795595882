<template>
  <v-card
    v-if="images.length > 0"
    elevation="0"
    :loading="isLoading"
    style="width: 100%; border: 1px solid;"
    :class="{'px-3 px-sm-6 pb-3 pb-sm-6': isProfileGroups, 'mt-4': !isProfileGroups}"
    :style="{
      borderColor: cardBorderColor ?
        $vuetify.theme.themes.light.boxBackground :
        $vuetify.theme.themes.light.box,
      backgroundColor: $vuetify.theme.themes.light.boxBackground
    }"
  >
    <v-card-title
      v-if="showCardTitle"
      class="d-flex pa-0 mt-3 mt-sm-6 align-center justify-space-between"
    >
      <button
        class="d-flex align-center"
        @click.prevent="seeProfile"
      >
        <v-avatar
          class="mr-3"
          size="36"
          color="transparentBackground"
        >
          <img
            v-if="profileImage"
            :src="profileImage"
          >
        </v-avatar>
        <span class="text-subtitle-2 text-sm-subtitle-1 font-weight-medium">{{ profileName }}</span>
      </button>
      <square-button
        color="#0000004d"
        icon-color="white"
        icon-name="mdi-close"
        fab
        :small="isMobileVersion"
        menu-disabled
        @clicked="$emit('close')"
      />
    </v-card-title>
    <v-carousel
      v-model="model"
      :class="{'mt-3 mt-sm-6': isProfileGroups}"
      :height="carouselHeight && !isMobileVersion ? carouselHeight : imageSize"
      hide-delimiters
      hide-delimiter-background
      show-arrows-on-hover
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
    >
      <v-carousel-item
        v-for="(img, i) in images"
        :key="i"
        eager
        :style="{ height: imageSize }"
        style="position: relative"
      >
        <image-container
          class="transparentBackground carouselImage"
          :class="{'carouselProfileImages': isProfileCarousel,
                   'carouselRelatedProfiles': isRelatedProfiles}"
          height="100%"
          width="100%"
          contain
          rounded
          :loading="loading"
          :src="loading ? '' : img.mediumImage || img.originalFrame || img.frame "
          :lazy-src="loading ? '' : img.frameThumbnail"
          :has-zoom="true"
          @isZoomDestroyed="removeZoomedImages"
          @isZoomEnabled="saveZoomedImages(i)"
          @reload="onReloadImageButtonClicked"
        />
        <div
          v-if="canShowBoundingBox && isBoundingBoxValid && !isZoomEnabled"
          :style="{
            height: `${boundingBoxStyle.height}px`,
            width: `${boundingBoxStyle.width}px`,
            top: boundingBoxStyle.top,
            left: boundingBoxStyle.left
          }"
          style="position: absolute; border: 1px solid #8F93FF"
        >
          <span
            class="d-flex justify-center align-center"
            :style="{
              position: 'absolute',
              background: '#8F93FF',
              bottom: '-22px',
              width: 'max-content',
              'min-width': '100%',
              height: '22px',
              'font-size': `${isMobileVersion ? '12px' : '18px'}`,
            }"
          >
            {{ boundingBoxSize(img) }}
          </span>
        </div>
        <div
          class="d-flex"
          style="position: absolute; top: 8px; left: 8px"
        >
          <div
            v-if="showFacePreview"
            class="d-flex"
            style="position: relative; min-height: 35px; min-width: 35px;
            transition: width 5s, height 5s"
          >
            <template v-if="showPreview">
              <image-container
                class="mr-1 transparentBackground elevation-2"
                rounded
                :loading="loading"
                :height="avatarSize"
                :width="avatarSize"
                :src="loading ? undefined : img.face"
                :lazy-src="loading ? undefined : img.faceThumbnail"
                @reload="onReloadImageButtonClicked"
              />
              <image-container
                class="mr-1 transparentBackground elevation-2"
                rounded
                :loading="loading"
                :height="avatarSize"
                :width="avatarSize"
                :src="profileImage"
                @reload="onReloadImageButtonClicked"
              />
            </template>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div
      v-if="isMobileVersion"
      class="d-flex mt-2 "
    >
      <div
        v-for="(img, i) in images"
        :key="i"
        class="circleDelimiterMobile"
        :style="{
          borderColor: model == i ? $vuetify.theme.currentTheme.primary : '',
          backgroundColor: model == i ? $vuetify.theme.currentTheme.primary : ''
        }"
        @click="model = i"
      />
    </div>
    <v-card-actions
      class="d-flex pa-0 mt-3 mt-sm-6 align-center flex-wrap"
      :class="{'justify-start': isMobileVersion, 'justify-space-between': !isMobileVersion}"
    >
      <div
        v-if="!isMobileVersion"
        class="d-flex align-center"
        :class=" {'mb-4': imageSize < 400 &&
          images.length > 2}"
      >
        <div
          v-for="(img, i) in images"
          :key="i"
          class="circleDelimiter"
          :style="{
            borderColor: model == i ? $vuetify.theme.currentTheme.primary : '',
            backgroundColor: model == i ? $vuetify.theme.currentTheme.primary : ''
          }"
          @click="model = i"
        />
        <span class="d-none d-md-flex ml-2 neutral--text subtitle-2 text-sm-subtitle-1">
          {{ model + 1 }}/{{ images.length }}
        </span>
      </div>
      <v-spacer v-if="!isMobileVersion" />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showFacePreview"
            outlined
            color="neutral"
            v-bind="attrs"
            :small="isMobileVersion"
            v-on="on"
            @click="setShowPreview"
          >
            <v-icon :small="isMobileVersion">
              {{ showPreview ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{
            showPreview ?
              $t('deconve.hideFacePreview') :
              $t('deconve.showFacePreview')
          }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            :disabled="isZoomEnabled"
            :class="{'ml-2': showFacePreview}"
            color="neutral"
            v-bind="attrs"
            :small="isMobileVersion"
            v-on="on"
            @click="setBoundingBoxStatus"
          >
            <v-icon :small="isMobileVersion">
              {{ showBoundingBox ? 'mdi-square-off-outline' : 'mdi-square-outline' }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{
            showBoundingBox ?
              $t('deconve.notification.unmarkFace') :
              $t('deconve.notification.markFace')
          }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            :color="hasOriginalImage ? 'info' : 'neutral'"
            :outlined="!hasOriginalImage"
            elevation="0"
            v-bind="attrs"
            :small="isMobileVersion"
            v-on="on"
            @click="$emit('downloadOriginalImage', model)"
          >
            <v-icon :small="isMobileVersion">
              mdi-quality-high
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('deconve.imageHighQuality') }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="showAddToProfile && $can('update', 'com.deconve.faceid.person')"
            class="ml-2 actionsButton"
            color="neutral"
            outlined
            v-bind="attrs"
            :small="isMobileVersion"
            v-on="on"
            @click="addImageToProfile"
          >
            <v-icon :small="isMobileVersion">
              mdi-plus-box-multiple
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('deconve.notification.addImageToProfile') }}
        </span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
  <div
    v-else
    class="flex-column align-end justify-end"
    style="flex: 1"
  >
    <v-skeleton-loader
      class="mb-2"
      type="image@3"
      :style="{ height: `${imageSize}px`, width: '100%' }"
    />
    <div class="d-flex justify-space-between">
      <v-skeleton-loader
        type="heading"
        style="flex: 1"
      />
      <v-skeleton-loader
        type="chip"
        tile
      />
    </div>
  </div>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import SquareButton from '@/components/SquareButton.vue';
import ImageContainer from '@/components/ImageContainer.vue';
import boundingBoxCalc from '@/utils/boundingBoxCalc';

export default {
  name: 'Carousel',
  components: {
    ImageContainer,
    SquareButton,
  },
  props: {
    images: { type: Array, required: true },
    index: { type: Number, required: true },
    numberOfImages: { type: Number, required: true },
    profileImage: { type: [String, Object], default: undefined },
    profileName: { type: String, default: '' },
    profileId: { type: String, default: '' },
    carouselHeight: { type: String, default: undefined },
    cardBorderColor: Boolean,
    showFacePreview: Boolean,
    showAddToProfile: Boolean,
    loading: Boolean,
    showCardTitle: Boolean,
    isProfileGroups: Boolean,
    isProfileCarousel: Boolean,
    isRelatedProfiles: Boolean,
  },
  data() {
    return {
      model: 0,
      showPreview: false,
      imageBoxes: [],
      profileImagesBoxes: [],
      relatedProfilesImagesBoxes: [],
      showBoundingBox: true,
      // TODO: bounding box should be computed by carousel image
      boundingBoxStyle: undefined,
      zoomedImages: [],
    };
  },
  computed: {
    isZoomEnabled() {
      return this.zoomedImages.some((index) => index === this.model);
    },
    canShowBoundingBox() {
      if (this.loading || !this.showBoundingBox) return false;

      const { frame, originalFrame, mediumImage } = this.images[this.model];

      const validFrame = frame || originalFrame || mediumImage;

      return validFrame && validFrame.length > 0;
    },
    isBoundingBoxValid() {
      return this.boundingBoxStyle?.width > 0;
    },
    isLoading() {
      if (this.loading) return true;

      const { frame, originalFrame, mediumImage } = this.images[this.model];

      if (frame || originalFrame || mediumImage) {
        return false;
      }

      return false;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    hasOriginalImage() {
      return this.images && this.images[this.model]?.originalFrame;
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '60px';
        case 'sm': return '100px';
        case 'md': return '125px';
        case 'lg': return '125px';
        case 'xl': return '125px';
        default: return '60px';
      }
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 258;
        case 'sm': return 448;
        case 'md': return 516;
        case 'lg': return 516;
        case 'xl': return 612;
        default: return 258;
      }
    },
  },
  watch: {
    numberOfImages() {
      if (this.model > this.numberOfImages) {
        this.model = this.index;
      }
    },
    index() {
      this.model = this.index;
    },
    canShowBoundingBox(ok) {
      if (ok) {
        this.imageBoxes = document.getElementsByClassName('carouselImage');
        this.profileImagesBoxes = document.getElementsByClassName('carouselProfileImages');
        this.relatedProfilesImagesBoxes = document.getElementsByClassName('carouselRelatedProfiles');
        this.getImageBoundingBox();
      } else {
        this.boundingBoxStyle = undefined;
      }
    },
    model(index) {
      this.$emit('downloadImage', index);
      this.$emit('changeNotificationImageIndex', index);

      if (this.images[index]) {
        this.getImageBoundingBox();
      }
    },
  },
  created() {
    this.model = this.index;
    window.addEventListener('resize', this.getImageBoundingBox);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getImageBoundingBox);
  },
  methods: {
    removeZoomedImages() {
      this.zoomedImages = [];
    },
    saveZoomedImages(indexImage) {
      const positionOnList = this.zoomedImages.indexOf(indexImage);

      if (positionOnList >= 0) {
        this.zoomedImages.splice(positionOnList, 1);
        return;
      }

      this.zoomedImages.push(indexImage);
    },
    getImageBoundingBox() {
      this.boundingBoxStyle = undefined;

      const { frame, originalFrame, mediumImage } = this.images[this.model];

      const selectedFrame = frame || originalFrame || mediumImage;

      if (!selectedFrame || selectedFrame.length <= 0) return;

      const img = new Image();

      img.onload = () => {
        const { originalHeight, originalWidth } = this.images[this.model];

        const { info } = this.images[this.model];
        const faces = info?.faces;

        // Compute bounding box style for notifications
        if (info && this.imageBoxes[this.model] && !faces && !this.isRelatedProfiles) {
          this.boundingBoxStyle = boundingBoxCalc({
            height: originalHeight,
            width: originalWidth,
            currentImageInfo: this.images[this.model].info,
            currentImageContainer: this.imageBoxes[this.model],
          });
        }

        if (info && !faces && this.relatedProfilesImagesBoxes[this.model]
        && this.isRelatedProfiles) {
          this.boundingBoxStyle = boundingBoxCalc({
            height: originalHeight,
            width: originalWidth,
            currentImageInfo: this.images[this.model].info,
            currentImageContainer: this.relatedProfilesImagesBoxes[this.model],
          });
        }

        // Compute bounding box style for profile images
        if (faces && faces.length > 0 && this.profileImagesBoxes[this.model]
        && !this.isRelatedProfiles) {
          this.boundingBoxStyle = boundingBoxCalc({
            height: originalHeight,
            width: originalWidth,
            // It's 0 because faces property has only 1 info per model
            currentImageInfo: faces[0].bounding_box,
            currentImageContainer: this.profileImagesBoxes[this.model],
          });
        }
      };

      img.src = selectedFrame;
    },
    boundingBoxSize(image) {
      const { info } = image;

      if (info) {
        const { faces } = info;

        // For profile images
        if (faces && faces.length > 0) {
          const { width, height } = faces[0].bounding_box;

          return `${width} x ${height}`;
        }

        // For notification images
        const { width, height } = info;

        return `${width} x ${height}`;
      }

      return '';
    },
    setBoundingBoxStatus() {
      this.showBoundingBox = !this.showBoundingBox;
    },
    setShowPreview() {
      this.showPreview = !this.showPreview;
    },
    addImageToProfile() {
      this.$emit('addImageToProfile', this.model);
    },
    seeProfile() {
      this.$router.push({ name: 'faceid-person', params: { personId: this.profileId } });
    },
    onReloadImageButtonClicked() {
      this.$emit('downloadImage', this.model);
    },
  },
};
</script>

<style scoped>
  .circleDelimiter {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    aspect-ratio: 1;
    border: 1px solid #DFE3E8;
    background-color: #EFEFEF;
    cursor: pointer;
  }
  .circleDelimiter + .circleDelimiter {
    margin-left: 4px;
  }

  .circleDelimiterMobile {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    aspect-ratio: 1;
    border: 1px solid #DFE3E8;
    background-color: #EFEFEF;
    cursor: pointer;
  }
  .circleDelimiterMobile + .circleDelimiterMobile {
    margin-left: 4px;
  }

  .actionsButton {
    @media (max-width: 300px) {
      margin-left: 0px !important;
      margin-top: 8px !important
    }
  }
</style>
